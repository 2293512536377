module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://staging.chalicenetwork.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"qagn27mv","include_in_development":true,"delay_timeout":2500},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
