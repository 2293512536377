import React from 'react'
import PropTypes from 'prop-types'
import { Img } from 'react-image'

import { UserMenu } from '../header/UserMenu'
import style from './adminheader.module.scss'

export const AdminHeader = ({ user, siteData, isLoading }) => {
  let logo = null
  if (siteData) {
    if (siteData.get('logo_url')) {
      logo = (
        <a href="/" className={style.logoLink}>
          <Img
            className={style.logo}
            src={siteData.get('logo_url')}
            alt={siteData.get('name')}
          />
        </a>
      )
    } else {
      logo = (
        <a href="/" className={style.logoLink}>
          { siteData.get('name') }
        </a>
      )
    }
  } else {
    logo = <a className={style.a} href="/">Your Site</a>
  }

  return (
    <nav className={style.navbar}>
      <div className={style.left}>
        { logo }
      </div>
      <div className={style.right}>
        <ul className="navbar-nav mr-auto align-items-center">
          <li key="usermenu" className="nav-item nav-relative">
            <UserMenu user={user} isLoading={isLoading} />
          </li>
        </ul>
      </div>
    </nav>
  )
}

AdminHeader.propTypes = {
  user: PropTypes.object,
  siteData: PropTypes.object
}
