import React, { Fragment } from 'react'
import classNames from 'classnames'
import {
  IoMdCheckmark,
  IoIosInformationCircleOutline
} from 'react-icons/io'

import { Link } from '../Button'
import style from './plan.module.scss'

export const PricingTable = ({ children }) => (
  <div className={ style.pricingTable }>
    { children }
  </div>
)

export const PriceTile = ({
  title, tag, className, barClass, frequency,
  monthlyPrice, annualPrice, description,
  url, urlText, showFeatures, features,
  trial = false,
  hideAction = false
}) => {
  let trialContent = null
  if (trial) {
    trialContent = '30 day free trial'
  }
  let price = null
  if (frequency === 'monthly') {
    price = (
      <div className={style.planPrice}>
        { monthlyPrice }
        <div className={style.priceDiv}>
          <strong className={style.strong}>
            annual commitment
          </strong>
        </div>
        <div className={style.planDeal}>
          { trialContent }
        </div>
      </div>
    )
  } else {
    price = (
      <div className={style.planPrice}>
        { annualPrice }
        <div className={style.priceDiv}>
          <strong className={style.strong}>save 20% over monthly</strong>
        </div>
        <div className={style.planDeal}>
          { trialContent }
        </div>
      </div>
    )
  }
  let featuresContent = null
  if (showFeatures) {
    featuresContent = (
      <div
        className={style.features}
        dangerouslySetInnerHTML={{
          __html: features
        }} />
    )
  }
  let actionContent = (
    <div className={style.actionRow} />
  )
  if (!hideAction) {
    actionContent = (
      <div className={style.actionRow}>
        <Link href={url}>{urlText}</Link>
      </div>
    )
  }
  return (
    <div className={classNames(style.plan, className)}>
      <div className={style.planTop}>
        <div className={style.planBox}>
          <div className={classNames(style.planTopHeader, 'secondary')}>
            <h2 className={style.h2}>{ title }</h2>
            <h3 className={style.h3}>{ tag }</h3>
            <div className={
              classNames(style.pricingBar, barClass, 'tertiary')}>
            </div>
          </div>
          <div className={style.planContent}>
            { price }
            <div className={style.planDescription} dangerouslySetInnerHTML={{
              __html: description
            }} />
            { featuresContent }
            { actionContent }
          </div>
        </div>
      </div>
    </div>
  )
}

export const Checkmark = ({ value }) => {
  if (!value) {
    return (
      <div className={style.nope}>
      </div>)
  } else if (typeof value === 'string') {
    return value
  } else {
    return (
      <div className={style.yup}>
        <IoMdCheckmark />
      </div>
    )
  }
}

const PricingSection = ({ section }) => (
  <>
    <tr>
      <td colSpan="4" className={style.pgHeader}>
        { section.name }
      </td>
    </tr>
    { section.products.map((r, i) => (<PricingRow row={r} key={i} />)) }
  </>
)

const PricingRow = ({ row }) => (
  <tr>
    <td className={style.pgTitleCell}>
      { row.name }
      <div>{ row.savings }</div>
      { false && row.tooltip && (
        <span><IoIosInformationCircleOutline /></span>
      ) }
    </td>
    <td className={style.pgCell}>
      <Checkmark value={ row.essentials || row.advantage } />
    </td>
    <td className={style.pgCell}>
      <Checkmark value={ row.ultimate } />
    </td>
  </tr>
)

const PricingHeader = ({
  title, plan, frequency,
  urlCallback, urlText, barClass
}) => (
  <td className={style.pgCell}>
    <div>
      <h2 class={style.pgCellH2}>{ title }</h2>
      <div>
        <Link href={urlCallback(plan, frequency)}>
          { urlText }
        </Link>
      </div>
    </div>
    <div className={classNames(style.pricingBar, barClass)} />
  </td>
)

export const PricingGrid = ({ plans, sections, urlText, urlCallback }) => {
  return (
    <>
      <table className={classNames(style.pricingGrid, style.pgSticky)}>
        <thead>
          <tr className={style.pgTierRow}>
            <td></td>
            {plans.map(plan => (
              <PricingHeader
                title={plan.title}
                barClass={plan.barClass}
                plan={plan.id}
                urlCallback={urlCallback}
                urlText={urlText}
                frequency={plan.frequency} />
            ))}
          </tr>
        </thead>
      </table>
      <table className={style.pricingGrid}>
        <tbody>
          { sections.map((section, i) => (
            <PricingSection
              key={i}
              section={section}
              urlText={urlText}
              urlCallback={urlCallback} />
          ))}
        </tbody>
      </table>
    </>
  )
}
