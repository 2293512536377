import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import style from './membershipbadge.module.scss'

export const MembershipBadge = ({ absolute, tierName, tier }) => (
  <span className={classNames(
    style.membershipBadge,
    absolute ? style.tierAbsolute : null)}>
    { tierName }
  </span>
)

MembershipBadge.propTypes = {
  tier: PropTypes.number,
  tierName: PropTypes.string,
  absolute: PropTypes.bool
}
