import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import smoothscroll from 'smoothscroll-polyfill'
import { Header, Footer } from '@chalicenetwork/chalice-style'
import Store from '../components/store'

import '../styles/app.scss'

if (typeof window !== 'undefined') {
  smoothscroll.polyfill()
}

const Logo = props => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(
          relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fixed(width: 200) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }`
    }
    render={ data => (
      <Link to="/" className="logo-link">
        <Img
          className="press-logo"
          alt="Chalice Logo"
          loading="eager"
          fadeIn={false}
          fixed={data.logo.childImageSharp.fixed} />
      </Link>
    )} />
)

export class GlobalWrapper extends Component {
  render () {
    Store.update()
    const { children } = this.props
    return (
      <div>
        <Header
          key="header"
          logo={<Logo />}
          user={Store.session}/>
        { children }
        <Footer key="footer" />
      </div>
    )
  }
}
