import React, { useEffect } from 'react'
import { Link } from '../Button'
import classNames from 'classnames'

import style from './chalicefooter.module.scss'
import button from '../button.module.scss'
import {
  FaFacebookSquare,
  FaLinkedin,
  FaTwitterSquare,
  FaYoutubeSquare,
  FaInstagramSquare
} from 'react-icons/fa'
import { BannersContainer } from '../banner_ads/BannersContainer'

const MultiViewAd = () => (
  <div id="mvleaderboard-footer"></div>
)

const launchIntercom = e => {
  if (typeof Intercom !== 'undefined') {
    window.Intercom('show')
  }
}

const LiLink = ({ href, children }) => (
  <li className={style.footerMainLi}>
    <a className={style.footerMainA} href={href}>
      {children}
    </a>
  </li>
)

const ProductLinks = () => (
  <ul className={classNames('list-unstyled', style.footerMainUl)}>
    <LiLink href='https://www.chalicenetwork.com/marketplace/'>
      Marketplace
    </LiLink>
    <LiLink href='https://successionlink.com'>Succession Link</LiLink>
    <LiLink href='https://www.chalicepay.com'>Chalice Pay</LiLink>
    <LiLink href='https://jobs.chalicenetwork.com'>Chalice Careers</LiLink>
    <LiLink href='https://www.chaliceconnect.com'>Chalice Connect</LiLink>
  </ul>
)

const SupportLinks = () => (
  <ul className={classNames('list-unstyled', style.footerMainUl)}>
    <LiLink href='/press/'>Press</LiLink>
    <LiLink href='/events/'>Events</LiLink>
    <LiLink href='https://help.chalicefn.com'>Help Center</LiLink>
    <LiLink href='/privacy-policy/'>Privacy Policy</LiLink>
    <LiLink href='/terms-of-service/'>Terms & Conditions</LiLink>
    <li className={style.footerMainLi}>
      <button className={style.footerMainA} onClick={launchIntercom}>
        Contact Us
      </button>
    </li>
  </ul>
)

const SubscribeForm = props => {
  let mounted = false
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    script.async = true
    script.onload = () => {
      if (!mounted) {
        window.hbspt.forms.create({
          portalId: '7233754',
          formId: 'b6d9090a-1462-442d-b1f5-c28aed4eeceb',
          target: '#subscribe-form'
        })
      }
      mounted = true
    }
    script.onerror = () => {
      console.log('Something went wrong trying to load the newsletter form.')
    }
    document.body.appendChild(script)
    return () => document.body.removeChild(script)
  })

  return <div className={style.formGroup} id='subscribe-form'></div>
}

export const ChaliceFooter = props => {
  const renderFooterTop = children => {
    if (children) {
      return <div className={style.flexCenter}>{children}</div>
    } else {
      return (
        <div className={style.flexCenter}>
          <div className=''>
            <h2 className={style.h3}>Get Started Now</h2>
            <p>
              Wherever you are in your business journey, Chalice has the tools
              and resources to help you overcome the most important challenges
              you face every day: getting more customers and growing your
              revenue.
            </p>
          </div>
          <div className={style.right}>
            <Link href='/pricing'>See Plans &amp; Pricing</Link>
          </div>
        </div>
      )
    }
  }

  return (
    <footer className={classNames(style.footer)}>
      <div className='container'>
        <div className={style.footerCta}>
          {renderFooterTop(props.children)}
          <BannersContainer banners={props.banners} placement='Footer' />
        </div>
        <MultiViewAd />
        <div className={style.footerMain}>
          <div>
            <div className='col-md-3 col-sm-4 col-xs-12'>
              <h2 className={style.footerMainH2}>Contact</h2>
              <p className={style.p}>
                930 S. Harbor City Blvd.
                <br />
                Suite 302
                <br />
                Melbourne, FL 32901
                <br />
                877-424-2542
              </p>
            </div>
            <div className='col-md-3 col-sm-4 col-xs-12'>
              <h2 className={style.footerMainH2}>Products</h2>
              <ProductLinks />
            </div>
            <div className='col-md-3 col-sm-4 col-xs-12'>
              <h2 className={style.footerMainH2}>Support</h2>
              <SupportLinks />
            </div>
            <div className='col-md-3 col-sm-12 col-xs-12'>
              <h2 className={style.footerMainH2}>Newsletter Signup</h2>
              <SubscribeForm />
              <div className={style.socials}>
                <a
                  href={
                    props.facebook_url ||
                    'https://business.facebook.com/chalicenetwork'
                  }
                  target='_blank'
                >
                  <FaFacebookSquare />
                </a>
                <a
                  href={
                    props.twitter_url || 'https://twitter.com/ChaliceNetwork'
                  }
                  target='_blank'
                >
                  <FaTwitterSquare />
                </a>
                <a
                  href={
                    props.instagram_url ||
                    'https://instagram.com/chalicenetwork/'
                  }
                  target='_blank'
                >
                  <FaInstagramSquare />
                </a>
                <a
                  href={
                    props.linkedin_url ||
                    'https://linkedin.com/company/chalicenetwork/'
                  }
                  target='_blank'
                >
                  <FaLinkedin />
                </a>
                <a
                  href={
                    props.youtube_url ||
                    'https://www.youtube.com/chalicenetwork'
                  }
                  target='_blank'
                >
                  <FaYoutubeSquare />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames(style.subFooter)}>
        <div className='container'>
          <div className='col-12'>
            <p className={style.subFooterP}>
              Chalice Network © 2022. All rights reserved.
            </p>
          </div>
          {props.disclaimer && <div className='col-12'>{props.disclaimer}</div>}
        </div>
      </div>
    </footer>
  )
}
